<template>
  <div class="form-group m-0">
    <div class="clearfix">
      <a
        v-if="type == 'password'"
        href="#"
        class="show-password"
        @click="switchVisibility"
      >
        <img
          src="@/assets/icon/ic-eyes.svg"
          :class="{ 'opacity-2': passwordFieldType == 'password' }"
        />
      </a>
      <input
        :id="id"
        :name="id"
        :ref="id"
        v-if="type == 'number'"
        v-model="model"
        type="text"
        class="form-control"
        :class="[classComponent]"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :aria-placeholder="placeholder"
        @focus="onfocus($event.target.value)"
        @blur="onblur($event.target.value)"
        @input="filterByNumber($event.target.value)"
        @keypress="$emit('keypress', $event)"
      />
      <textarea
        :id="id"
        :name="id"
        :ref="id"
        v-else-if="type == 'textarea'"
        v-model="model"
        class="form-control"
        :class="[classComponent]"
        rows="4"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :aria-placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
      />
      <input
        :id="id"
        :name="id"
        :ref="id"
        v-else
        v-model="model"
        :type="type == 'password' ? passwordFieldType : type ? type : 'text'"
        class="form-control"
        :class="[classComponent]"
        :disabled="disabled"
        :readonly="readonly"
        :max="type == 'date' ? max : null"
        :min="type == 'date' ? min : null"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :aria-placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
        :autocomplete="type == 'password' && disableAutoComplete ? 'new-password' : type && disableAutoComplete ? 'off' : ''"
      />
      <small
        class="text-grey"
        v-html="info ? info.replace(/(\d+)/g, '<b>$1</b>') : info"
      ></small>
      <!-- Invalid Form Message -->
      <div
        class="invalid-feedback text-right"
        v-if="invalidMessage && invalidMessage.length > 0"
      >
        <div
          v-for="(message, index) in invalidMessage"
          :key="message + '-' + index"
          :class="{
            'mb-1':
              invalidMessage.length > 1 && index + 1 != invalidMessage.length,
          }"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputText",
  props: [
    "id",
    "classComponent",
    "disabled",
    "invalidMessage",
    "info",
    "max",
    "min",
    "maxlength",
    "placeholder",
    "readonly",
    "type",
    "value",
    "disableAutoComplete"
  ],
  data() {
    return {
      model:
        this.type == "number" && this.value
          ? this.value.toLocaleString("id")
          : this.value,
      mask: this.value,
      passwordFieldType: this.type,
    };
  },
  methods: {
    filterByNumber(val) {
      const index = val.search(/[^-.0-9]|^-*0\d|\.{2,}/);
      if (index != -1) this.model = val.substr(0, index);
    },
    onfocus(val) {
      this.model = this.mask;
      this.mask = val;
    },
    onblur(val) {
      this.model = val ? parseFloat(val).toLocaleString("id") : null;
      this.mask = val;
      this.$emit("input", val ? parseFloat(val) : null);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType == "password" ? "text" : "password";
    },
  },
  watch: {
    value(newVal) {
      if (this.type == "number") {
        this.model = newVal ? parseFloat(newVal).toLocaleString("id") : null;
        this.mask = newVal;
      } else if (newVal) {
        if (newVal.length == 1 && newVal.charAt(0) == " ") newVal = null
        else if (newVal.length > 1) {
          const prevChar = newVal.charAt(newVal.length - 2)
          const newChar = newVal.charAt(newVal.length - 1)
          if (prevChar == " " && newChar == " ") newVal = newVal.substr(0, newVal.length - 2)
        }
        this.$emit("input", newVal)
        this.model = newVal
      }
    },
  },
};
</script>
